.report-widget
  display: flex
  padding: clamp(16px, 2.5vw, 32px)
  flex-direction: column
  gap: 20px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
  position: relative

  &__graphics
    display: flex
    justify-content: space-between
    height: 100%

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 16px

    &--column
      flex-direction: column
      align-items: flex-start

  &__inner
    display: flex
    flex-direction: column

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 2.4rem
    font-weight: 700

    &--sm
      font-size: 1.6rem

  &__subtitle
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.4rem
    font-weight: 300

  &__filter
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 16px

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column

    .empty-state, .loader-spinner-wrapper
      margin: auto

  &__help-icon
    cursor: help
    color: var(--color-neutral)
    width: 20px
    position: absolute
    top: 8px
    right: 8px
    transition: all 0.15s

    &:hover
      color: var(--color-primary)

  &__legend-wrapper
    flex-shrink: 0
    display: flex,
    flex-direction: column,
    width: 136px,
    gap: 12px

  &__legend
    font-size: 1.2rem
    font-weight: 700
    display: flex
    padding: 10px
    flex-direction: column
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #fff

    &-title, &-count
      margin-bottom: 0

    &-title
      color: var(--color-neutral-darker, #5d6a86)

    &-count
      color: var(--color-primary-dark, #110B3E)
