.swal2
  &-close
    color: var(--color-primary)
    background-color: var(--color-primary-lightest)
    border-radius: 4px
    width: 24px
    height: 24px
    margin-bottom: 0
    position: absolute
    right: 8px
    top: 8px

    &:hover, &:focus
      color: var(--color-primary)
      background-color: var(--color-primary-lightest)

  &-title
    padding: 0
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.6rem

  &-html-container
    color: var(--color-neutral, #8894AC) !important
    font-size: 1.4rem !important
    font-weight: 300 !important

  &-actions
    gap: 12px

  &-styled
    border-radius: 100px !important
    font-size: 1.3rem !important
    padding: 8px 20px !important
    height: 40px
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    font-weight: 700
    margin: 0

  &-confirm
    background-color: var(--color-primary) !important
    &:focus
      box-shadow: none !important

  &-cancel
    background: white !important
    border: 1px solid var(--color-primary-lightest) !important
    color: var(--color-primary) !important

  &-deny
    background: var(--color-danger) !important

div:where(.swal2-container) div:where(.swal2-popup)
  display: none
  position: relative
  box-sizing: border-box
  grid-template-columns: minmax(0, 100%)
  width: 564px
  max-width: 80vw
  padding: 32px
  border: none
  border-radius: 12px
  background: #fff
  color: #545454
  font-family: inherit
  font-size: 1rem
