.lesson-dg
  display: flex
  flex-direction: column
  gap: 12px

  &__row
    display: flex
    flex-wrap: wrap
    align-items: center
    border-radius: 12px

    &:hover
      background: var(--color-bright)

  &__td
    padding: 10px
    width: 120px
    flex-grow: 1
    display: flex
    align-items: center
    gap: 4px

    &--files
      flex-grow: 0
      width: 120px

    &--time
      flex-grow: 0
      width: 84px

    &--title
      width: 100%
      @media (min-width: $sm)
        width: auto

  &__title, &__text, &__link
    margin-bottom: 0
    font-size: 1.4rem
    font-weight: 300

  &__title
    @extend .u-text-truncate
    color: var(--color-primary-dark, #110B3E)
    width: 110px
    flex-grow: 1

  &__text, &__link
    color: var(--color-neutral, #8894AC)

  &__link
    text-decoration: underline

  &__check
    flex-shrink: 0
    display: inline-block
    width: 12px
    height: 12px
    background-color: #CCD2E0
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    border: 1px solid #5D6A86
    border-radius: 100px

    &--checked
      border-color: var(--color-success)
      background-color: var(--color-success)
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
      background-size: .6em
      background-position: center
      background-repeat: no-repeat
