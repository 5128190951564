.password-strength
  display: flex
  flex-direction: column
  gap: 4px
  padding: 20px
  position: absolute
  z-index: 1
  right: 8px
  bottom: calc(100% + 20px)
  border-radius: 10px
  background: #fff
  box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)
  width: 240px
  max-width: 100%
  color: var(--color-neutral-dark, #5D6A86)
  font-size: 1.2rem
  font-weight: 300
  cursor: default

  &-wrapper
    position: relative

  &__title, &__text
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    font-weight: 700

  &__body
    display: flex
    flex-direction: column
    gap: 6px

  &__text
    display: flex
    align-items: center
    gap: 6px

    &--danger
      color: var(--color-danger)

    &--success
      color: var(--color-success)

  &__icon
    flex-shrink: 0
    stroke-width: 3px
    width: 16px
    display: none
    transition: .16s

    .password-strength__text--danger &, .password-strength__text--success &
      display: inline-block
