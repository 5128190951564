////
/// @BASE -> PAGE
////

html
  background: var(--color-neutral-bright)
body
  background: var(--color-neutral-bright)

.main-screen
  +i(1/1)
  min-height: 100vh
  position: relative
  +g(space-between)

  > div[tabindex]
    width: 100%

.main-content
  +g(space-between)
  padding: 110px 16px 16px 16px
  box-sizing: border-box
  width: 100%
  max-width: 100vw
  min-height: 100vh
  overflow-x: hidden
  overflow-y: auto
  background: var(--color-neutral-bright)
  +bp(md)
    padding: 104px 3em 3em 3em
    margin-left: 64px
    width: calc( 100% - 64px )
    .main-screen--admin &
      margin-left: 0
      width: 100%
    .main-screen--admin .main-sidebar + &
      margin-left: 260px
      width: calc( 100% - 260px )

  &--block
    display: block

  &--column
    flex-direction: column
    align-items: stretch
    justify-content: flex-start

  &--no-margin
    margin: 0 !important
    width: 100%
    padding: 0

  &--admin-layout
    padding-top: 72px
    +bp(md)
      padding-top: 44px

  &--auditorium
    flex-direction: column
    justify-content: flex-start

    .page
      flex-grow: 1
      align-items: stretch

.texto-teste
  z-index: 10000
  position: relative

.whatsapp-button
  position: fixed
  z-index: 99999
  bottom: 20px
  right: 20px
  border-radius: 100%
  background-color: rgb(37, 211, 102)
  color: rgb(255, 255, 255)
  padding: 10px
