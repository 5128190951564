.main-content-base
	+g(full, space-between)
	position: relative
	gap: 1em 0

	.breadcrumb
		+i(1/1)

	.classroom-video-content
		width: 100%
		padding: .5em
		@media (min-width: $lg)
			width: 70%

	.classroom-video-content-sidebar
		width: 100%
		position: relative
		right: 0px
		@media (min-width: $lg)
			position: fixed
			right: 45px
			width: 20%
			height: calc( 100vh - 105px)
			display: flex
			flex-direction: column

	.tab
		padding: 6px
		margin-bottom: 0px

	.tab__pane
		width: 100%
		@media (min-width: $lg)
			width: 70%

		.u-mb-4
			&:last-of-type
				margin-bottom: 0px!important
