.class-modules
  height: 300px
  flex-grow: 1
  overflow-y: auto
  overflow-x: hidden

.class-module
  box-shadow: $dropshadow
  border: 1px solid var(--color-neutral-lightest)
  border-radius: 10px
  position: relative

  &:not(:last-of-type)
    margin-bottom: 16px

  &__body, &__header
    +g(full, middle, space-between)
    gap: 8px

  &__header
    padding: 12px
    cursor: pointer
    position: relative

  &__new-message-notification
    position: absolute
    right: 0px
    top: 0px
    background-color: var(--color-third-light)
    color: white
    width: 20px
    height: 20px
    +g(full, center, middle)
    border-radius: 50px
    top: -5px
    right: -5px
    font-size: 11px

  &__content-item-messages
    +g(full, center, middle)
    font-size: .85em
    border: 1px solid var(--color-neutral-light)
    padding: .2em 0
    width: 40px
    gap: .2em
    border-radius: 5px
    &.new
      background-color: var(--color-third)
      color: white
      border: 1px solid var(--color-third-light)

  &__body
    flex-grow: 1

  &__icon
    flex-shrink: 0
    +g(full, center, middle)
    width: 25px
    height: 25px
    border-radius: 200%
    margin-right: .5em
    border: 1px solid var(--color-neutral-light)
    svg
      width: 14px
      height: 14px
      color: var(--var-neutral)
    &.disabled
      opacity: .3
    &.done
      background: var(--color-success)
      svg
        color: white

  &__actions
    +g(full, middle)
    gap: .5em
    svg
      width: 14px
      color: var(--color-neutral)

  &__title
    font-weight: 700
    font-size: .88em
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    width: 0
    flex-grow: 1
    &.disabled
      opacity: .3

  &__count
    font-weight: 300
    font-size: .7em
    color: var(--color-neutral)

  &__content-list
    background: var(--color-bright)
    +g(full)
    border-radius: 0 0 10px 10px
    display: none
    &.active
      display: block

  &__content-item
    +i(1/1)
    +g(full, middle)
    gap: 8px
    font-size: .8em
    padding: 10px 12px
    &:last-child
      border-radius: 0 0 10px 10px

    &:hover
      background-color: white
      cursor: pointer
    &.active
      background-color: var(--color-primary)
      color: white
      .class-module__content-item-title
        color: #fff
        font-weight: 700
    &.disabled
      cursor: not-allowed
    &--requirement
      .class-module__content-item-title
        text-decoration: underline

  &__content-item-title
    color: var(--color-neutral)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    width: 0
    flex-grow: 1

  &__content-item-icon
    svg
      color: var(--color-success)
    +g(full, center, middle)
    width: 18px
    height: 18px
    border: 1px solid var(--color-primary-lighter)
    background: white
    border-radius: 100%
    &.done
      border: 1px solid var(--color-success)
    &--requirement
      border-radius: 0
      border: 0
      background: transparent
      svg
        color: var(--color-neutral)
        width: 16px
        height: 16px
