.pageWithLayouts {
  width: 100vw;
  margin-top: 0;

  &.hasCustomLogos {
    margin-top: 68px;
    @media (min-width: 784px) {
      margin-top: 0;
    }
  }
}
