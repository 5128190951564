.notes
  background-color: #fff
  box-shadow: 0px 4px 10px rgba(119, 119, 120, 0.15)
  overflow-x: hidden
  overflow-y: auto
  height: 100%
  display: flex
  flex-direction: column
  position: relative
  z-index: 0

  &-wrapper
    position: fixed
    top: 46px
    right: 0
    bottom: 0
    z-index: 1001
    width: calc( 100% - 64px )
    transform: translateX(100%)
    transition: transform .3s ease-in-out
    @media (min-width: 784px)
      top: 0
      width: 960px
      max-width: calc( 100vw - 120px )

    &.show
      transform: translateX(0)

    &.has-custom-logos
      top: calc(68px + 46px)

      @media (min-width: 784px)
        top: 0

  &-toggle
    appearance: none
    border: 0
    background-color: var(--color-primary)
    cursor: pointer
    color: #fff
    line-height: 1
    padding: 8px 8px
    border-bottom-left-radius: 10px
    position: absolute
    top: 0
    left: 0
    transform: translateX(-100%)
    font-size: 12px
    font-weight: 700
    display: flex
    align-items: center
    gap: 8px
    transition: all .15s

    &:hover
      box-shadow: $dropshadow-md

    svg
      width: 20px

    &__text
      display: none
      @media (min-width: 784px)
        display: inline-block

  &-backdrop
    position: fixed
    top: 0
    left: 0
    z-index: 1000
    width: 100vw
    height: 100vh
    background-color: rgba(255, 255, 255, .4)

  &__header
    padding: 24px 0
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 16px
    border-bottom: 1px solid var(--color-neutral-lightest)
    margin: 0 24px 24px 24px
    & > :last-child
      margin-bottom: 0

  &__body
    overflow-y: auto
    flex-grow: 1
    padding: 0 24px 24px 24px
    display: flex
    gap: 16px
    flex-direction: column
    @media (min-width: $sm)
      gap: 68px
      flex-direction: row

  &__aside
    flex-shrink: 0
    width: auto
    height: 132px
    display: flex
    flex-direction: column
    @media (min-width: $sm)
      width: 204px
      height: auto

    .filter-bar__select
      width: 100%

    .filter-bar__label
      display: none

  &__nav
    margin: 12px 0 0 0
    display: flex
    flex-direction: column
    gap: 12px
    height: 328px
    padding-right: 16px
    margin-right: -16px
    overflow-y: auto
    flex-grow: 1

  &__btn
    appearance: none
    border: 0
    padding: 12px
    display: flex
    flex-direction: column
    width: 100%
    cursor: pointer
    color: #110B3E
    background: #fff
    text-align: left
    font-size: 1.2rem
    line-height: 1.2
    border: 1px solid var(--color-neutral-lightest)
    border-radius: 4px
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    position: relative

    &.active
      background: var(--color-bright)

  &__btn-header
    display: flex
    align-items: center
    gap: 8px
    width: 100%

    .c-dropdown__toggle
      padding: 0
    .card__icon-wrapper
      padding: 4px

  &__btn-text, &__btn-date
    display: block

  &__btn-text
    font-weight: 700
    font-size: 1.4rem
    color: var(--color-primary-dark)

  &__btn-date
    @extend .u-text-truncate
    flex-grow: 1
    font-weight: 300
    font-size: 1.2rem
    color: var(--color-neutral)

  &__btn-body
    margin-bottom: 28px

  &__tag
    position: absolute
    top: 0
    right: 10px

  &__btn-body
    flex-grow: 1

  &__author
    font-weight: 300
    font-size: 1.2rem
    color: var(--color-neutral-dark)
    display: flex
    align-items: center
    gap: 8px

  &__view
    flex-grow: 1
    overflow-y: auto
    display: flex
    flex-direction: column

    &-header
      margin-bottom: 16px
      display: flex
      align-items: flex-start
      gap: 12px

    &-inner-header
      flex-grow: 1

    &-title
      margin-bottom: 0
      font-weight: 700
      font-size: 1.6rem
      color: var(--color-primary-dark)

    &-date
      margin-bottom: 0
      font-weight: 300
      font-size: 1.2rem
      color: var(--color-neutral)

    &-body
      height: 100px
      flex-grow: 1
      overflow-y: auto
      display: flex
      flex-direction: column

      .ck-editor
        flex-grow: 1
        display: flex
        flex-direction: column

        &__main, &__editable
          height: 60px
          flex-grow: 1

        &__main
          display: flex
          flex-direction: column

      .ck-blurred
        background-color: #fff
        transition: .15s

        &:hover
          background-color: var(--color-bright)

  &__important-btn
    appearance: none
    background-color: #fff
    border: 1px solid var(--color-neutral-lightest)
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 4px
    padding: 6px 12px

    &--added
      path
        fill: #FDB462

.text-classroom-note
  display: block
  width: fit-content
