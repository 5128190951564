.live-class-card
  display: flex
  padding: 12px
  flex-direction: column
  gap: 20px
  border-radius: 10px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
  font-size: 1.2rem
  transition: .15s

  &:hover, &:focus
    box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.2)

  &__header
    line-height: 1

  &__inner-header
    display: flex
    justify-content: space-between
    margin-bottom: 8px

  &__title, &__subtitle, &__time
    @extend .u-text-truncate
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-weight: 700

  &__subtitle
    color: var(--color-neutral, #8894AC)
    font-weight: 300

  &__time
    display: flex
    align-items: center
    gap: 4px
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.1rem
    font-weight: 300

.live-class-player
  border-radius: 12px
  background-color: #fff
  box-shadow: $dropshadow
  padding: 12px
  width: 100%
  display: flex
  flex-direction: column

  &__embed
    border-radius: 12px
    overflow: hidden
    transform: translateY(0px)
    flex-grow: 1
    aspect-ratio: 16 / 9
    position: relative
    iframe
      position: absolute
      width: 100%
      height: 100%

.live-class-player_overlay
  background-color: rgba(#000, 0.72)
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  bottom: 0
  right: 0
  display: flex
  justify-content: center
  align-content: center
  flex-wrap: wrap
  &--noise
    &:before, &:after
      content: ""
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      bottom: 0
      right: 0
    &:before
      background-color: rgba(#000, 0.72)
      z-index: 2
    &:after
      z-index: 1
      background-image: url('../../images/bg_noise.gif')

  &__inner
    text-align: center
    position: relative
    z-index: 10

  &__title, &__body
    color: var(--color-neutral-bright)

  &__title
    font-weight: 700
    font-size: 1.3em

  &__body
    font-weight: 300
    font-size: 1em
    margin: 5px auto

.live-class-about
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 20px
  width: 100%
  overflow-x: hidden
  word-wrap: break-word

  &__title, &__text
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

  &__body
    height: 80px
    overflow-y: auto
    flex-grow: 1

  &__text
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300

.card-next-live-class
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 20px
  padding: 24px 32px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: linear-gradient(254deg, #FFF 5.22%, rgba(255, 255, 255, 0.00) 99.41%)
  box-shadow: $dropshadow

  &__header, &__body, &__footer
    width: 100%

  &__hat, &__title, &__time
    margin-bottom: 0

  &__hat
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 300

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

  &__time
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
    display: flex
    align-items: center
    gap: 4px
