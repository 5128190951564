.title {
  color: var(--color-primary-dark);
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

.info {
  font-size: 1.4rem;
  margin-bottom: 0;
  color: var(--color-neutral);
}

.competencieHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.score {
  padding: 12px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.competencieDescription {
  font-size: 1.2rem;
  color: var(--color-neutral);
  margin-bottom: 1.6rem;
}

.bgSuccess {
  background-color: var(--color-success);
}

.bgWarning {
  background-color: var(--color-warning);
}

.bgDanger {
  background-color: var(--color-danger);
}

.successText {
  color: var(--color-success);
}

.warningText {
  color: var(--color-warning);
}

.dangerText {
  color: var(--color-danger);
}
