.oros
  --color-primary-dark:rgb(63, 112, 168)
  --color-primary: #8db7e8
  --color-primary-light:rgb(183, 211, 248)
  --color-primary-lighter: #ffffff
  --color-primary-lightest: #ffffff

  --color-secondary-dark: #17191c
  --color-secondary: #222529
  --color-secondary-light: #2d3238
  --color-secondary-lighter: #31373d
  --color-secondary-lightest: #383f46

