.modal-header {
  background-color: var(--color-primary-light);
  width: '100%';
  height: 200px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.user-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: fit-content;
  padding: 10px;
  padding-left: 30px;
  gap: 15px;
  margin-top: -90px;
}

.user-info .pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: -20px;
}

.user-info .container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  margin-top: -20px;
  & .title {
    font-size: 18px;
    font-weight: 700;
    color: white;
    padding: 0;
    margin: 0;
  }
}

.report-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}