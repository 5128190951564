.card
    @extend .u-box
    padding: 16px 20px
    display: flex
    flex-direction: column
    border: 1px solid var(--color-neutral-lightest)
    position: relative

    &--milestone
        width: 100%

    &__topbar
        +i(1/1)

    &__switcher
        padding: 0 .5em

    &--h
        flex-direction: row
        gap: 16px
        overflow: hidden

        .card__header, .card__body
            margin-bottom: 0
            display: flex
            align-items: center

        .card__header
            flex-grow: 1
            width: 80px
            max-width: 100%
            @media (min-width: $lg)
                flex-grow: 0

        .card__video
            height: 120px
            width: 213px
            background-color: black
            overflow: hidden
            border-radius: 10px
            position: relative
            > iframe
                position: absolute
                width: 100%
                height: 100%

        .card__body
            width: 0
            justify-content: center
            display: none
            @media (min-width: $lg)
                display: flex

        .card__badges
            flex-wrap: nowrap

    &--discipline
        flex-wrap: wrap

        .card__header
            width: 236px

    &--danger
        border: 1px solid var(--color-danger)

    &--warning
        border: 1px solid var(--color-warning)

    &--group
        background-color: var(--color-bright)
        border: 1px solid var(--color-neutral-lightest)
        position: relative

        &::before, &::after
            content: ''
            background-color: #fff
            position: absolute
            inset: 0
            border: 1px solid var(--color-neutral-lightest)
            border-radius: 12px

        &::before
            z-index: -1
            transform: translate(-6px, -6px)

        &::after
            z-index: -2
            transform: translate(-12px, -12px)

    &--bright
        background-color: var(--color-bright)
        border: 1px solid var(--color-neutral-lighter)

    &--simple
        border: 1px solid var(--color-neutral-lightest)
        box-shadow: none

        &:hover
            box-shadow: none

    &--p-md
        padding: 24px 32px

    &--study-blocks
        border: 1px solid var(--color-neutral-lighter, #CCD2E0)
        background: #FAFAFA
        margin-top: 28px

    &-wrapper
        border-top: 1px solid var(--color-neutral-lightest)
        margin-top: 32px
        padding-top: 32px
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr))
        gap: 16px

        &--mh
            overflow-y: auto
            max-height: 300px
            padding-right: 16px

    &__container
        flex-grow: 1
        display: flex
        align-items: flex-start
        gap: 16px

    &__content
        width: 0
        flex-grow: 1
        align-self: stretch
        display: flex
        flex-direction: column

    &__header
        margin-bottom: 16px

        > :last-child
            margin-bottom: 0

    &__header-img
        margin-bottom: 16px
        width: 100%
        height: 80px
        border-radius: 12px
        object-fit: cover
        border: 1px solid var(--color-neutral-lightest)

        &--h
            width: 276px
            height: 122px

        &-wrapper
            position: relative
            margin-bottom: 16px

            &::before
                content: ''
                position: absolute
                inset: 0
                background-color: rgba(0, 0, 0, .4)
                border-radius: 12px

            > :last-child
                margin-bottom: 0

    &__inner-header
        display: flex
        align-items: center
        gap: 16px
        margin-bottom: 8px
        width: 100%

        .badge, .medal
            flex-shrink: 0

    &__week-nav
        flex-grow: 1
        display: flex
        align-items: center
        justify-content: space-between
        gap: 12px
        text-align: center
        position: relative

        &-title
            @extend .u-text-truncate
            flex-grow: 1
            position: relative
            min-height: 20px
            overflow: hidden

            .card__title
                position: absolute
                left: 50%
                transform: translateX(-50%)
                max-width: 44%

    &__text-schedule-wrapper
        flex-shrink: 0
        display: flex
        flex-direction: column
        align-items: flex-end
        gap: 2px

    &__text-schedule
        flex-shrink: 0
        display: inline-block
        width: 100px
        color: var(--brand-neutral-neutral, #8894AC)
        text-align: right
        font-size: 1rem
        font-weight: 300
        line-height: 1.2
        margin-bottom: 0

    &__title
        color: var(--color-primary-dark)
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 0
        min-width: 20px
        flex-grow: 1
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        span
            font-weight: 300

        &--truncate
            @extend .u-text-truncate
            width: 60px
            display: block

    &__subtitle
        display: block
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.2rem
        margin-bottom: 16px

    &__description
        margin-bottom: 16px
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        height: 42px

    &__meta
        flex-shrink: 0
        font-weight: 700
        margin-bottom: 0
        font-size: 1.2rem
        color: var(--color-neutral)
        padding-left: 12px
        position: relative

        &::after
            content: ''
            background-color: var(--color-neutral)
            width: 4px
            height: 4px
            border-radius: 100px
            position: absolute
            top: 50%
            left: 0
            transform: translate(-50%, -50%)

    &__body
        flex-grow: 1
        margin-bottom: 16px
        display: flex
        flex-direction: column
        justify-content: flex-end

        > :last-child
            margin-bottom: 0

    &__columns
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 12px
        margin-bottom: 16px

        &--mobile
            grid-template-columns: 1fr
            +bp(md)
                grid-template-columns: repeat(2, 1fr)

        & > *
            width: 100%

        &--h
            grid-template-columns: repeat(auto-fill, minmax(80px, 1fr))

        &--bg
            background-color: var(--color-bright)
            border-radius: 12px
            padding: 20px

            .card__count
                justify-content: left
                padding: 0
                color: var(--color-primary-dark)

            .card__count-label
                text-align: left

    &__progress
        font-size: 1.2rem
        font-weight: 300
        color: var(--color-primary-lighter)
        display: flex
        align-items: center
        gap: 8px
        margin-bottom: 16px

        &--primary
            color: var(--color-primary)

        &--t2
            .card__progress-container
                height: 12px

            .card__progress-bar
                border-radius: 100px
                background-color: var(--color-success)

            .card__progress-text
                color: var(--color-neutral-dark)

        &--cover
            width: 288px
            max-width: 100%

            .card__progress-container
                height: 12px
                background: rgba(255,255,255, .2)

            .card__progress-bar
                border-radius: 10px
                background: linear-gradient(180deg, #6FCF97 0%, #19C761 100%)

            .card__progress-text
                color: #fff
        &--value-first
            align-items: flex-start
            flex-wrap: wrap-reverse

        &-container
            width: 100%
            flex-grow: 1
            height: 6px
            background-color: var(--color-neutral-lightest)
            border-radius: 10px
            overflow: hidden

        &-bar
            height: 100%
            background-color: var(--color-primary)
            transition: all ease-in-out 1s .5s

        &-text
            flex-shrink: 0

    &__box
        color: var(--color-neutral)
        font-size: 1.4rem
        font-weight: 700
        min-height: 44px
        padding: 4px
        border-radius: 4px
        border: 1px solid var(--color-bright)
        display: flex
        align-items: center
        justify-content: center
        flex-wrap: wrap
        gap: 2px 8px
        margin-bottom: 0
        cursor: default
        text-align: center

        span
            font-size: 1.2rem
            font-weight: 300

    &__count
        @extend .card__box
        background-color: transparent

        .card--group &
            background-color: #fff

        &--shadow
            box-shadow: $dropshadow

        &-label
            @extend .u-text-truncate
            margin-bottom: 0
            color: var(--color-neutral)
            font-size: 1.2rem
            font-weight: 300
            width: 100%
        &--clickable
            cursor: pointer

    &__info
        @extend .card__box
        line-height: 1
        gap: 0

        &--danger
            color: var(--color-danger)

    &__icon
        color: var(--color-primary)
        flex-shrink: 0
        min-width: 16px
        position: relative

        &--md
            min-width: 20px

        &--semicircle
            &::before
                content: ''
                position: absolute
                inset: 0 0 0 50%
                background-color: rgba(255, 255, 255, .8)
            svg
                min-width: 16px

        &--secondary
            color: var(--color-secondary)

        &-wrapper
            flex-shrink: 0
            background-color: var(--color-primary-lightest)
            color: var(--color-primary)
            line-height: 0
            padding: 8px
            border-radius: 4px
            display: inline-block
            border: 0
            position: relative

            &--sm
                padding: 4px

            &--md
                width: 52px
                height: 52px
                display: flex
                align-items: center
                justify-content: center
                svg
                    min-width: 20px

            .icon--verified
                position: absolute
                top: 0
                left: 0
                z-index: 1
                translate: -50% -50%

        &--star
            color: #FFD661
            fill: #FFD661

        &--golden
            color: #FFD661

        &--silver
            color: #8894AC

        &--bronze
            color: #D3681A

    &__print-btn
        color: var(--color-primary)
        width: 32px
        height: 32px
        flex-shrink: 0
        background-color: #fff
        border: 1px solid var(--color-neutral-lightest)
        border-radius: 50px
        padding: 0
        display: flex
        align-items: center
        justify-content: center
        svg
            width: 16px

        &:active
            color: #fff
            background-color: var(--color-primary)
            border-color: var(--color-primary)

    &__print-empty
        color: var(--color-primary)
        width: 32px
        height: 32px
        flex-shrink: 0
        background-color: #fff
        padding: 0
        display: flex
        align-items: center
        justify-content: center
        pointer-events: none
        border: none
        svg
            width: 16px

    &__avatar
    &__avatar-title
        font-size: 1.2rem
        font-weight: 300
        color: var(--color-neutral)
        margin-bottom: 4px

    &__badges
        line-height: 0
        display: flex
        flex-wrap: wrap
        gap: 4px
        margin-bottom: 16px
        max-width: 100%

        &--one-line
            flex-wrap: nowrap

    &__badge-title
        margin-bottom: 4px
        font-weight: 700
        color: var(--color-neutral-dark)
        font-size: 1.2rem

    &__footer
        display: flex
        align-items: center
        gap: 16px

        .btn
            flex-grow: 1

    .avatar
        flex-shrink: 0

    > :last-child
        margin-bottom: 0

    &--clickable
        cursor: pointer
        padding: 2em
        justify-content: center
        &:hover
            background-color: var(--color-bright)
    .form__check
        margin-bottom: 16px

    &__todo
        background: #fff
        border: 1px solid #ECE9F8
        border-radius: 4px
        padding: 20px
        transition: .15s
        display: flex
        flex-direction: column
        gap: 24px

        &:hover
            box-shadow: 0px 4px 10px 3px rgba(68, 43, 140, 0.2)

        &-wrapper
            display: grid
            grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr))
            gap: 20px
            overflow-y: auto
            padding: 10px
            margin: 0 -10px
            flex-grow: 1

        &-inner
            flex-grow: 1
            width: 120px

        &-header
            display: flex
            align-items: center
            justify-content: space-between
            gap: 12px

        &-medal-check
            flex-shrink: 0

        &-title
            @extend .u-text-truncate
            flex-grow: 1
            margin-bottom: 0
            font-weight: 700
            font-size: 1.8rem
            color: var(--color-primary)

        &-body
            flex-grow: 1
            max-width: 100%
            display: flex
            flex-direction: column
            gap: 12px

        &-assets
            display: flex
            flex-wrap: wrap
            align-items: center
            gap: 12px

        &-count
            display: flex
            align-items: center
            gap: 4px
            color: var(--color-neutral)
            svg
                width: 16px

        &-check-input
            flex-shrink: 0
            appearance: none
            margin: 0
            width: 20px
            height: 20px
            border: 1px solid var(--color-neutral)
            border-radius: 50px
            background-color: #fff
            transition: all ease-in-out .2s
            background-position: center
            cursor: pointer
            outline: none

            &:checked
                border-color: var(--color-success)
                background-color: var(--color-success)
                background-repeat: no-repeat
                background-position: center
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
                background-size: 12px auto
                outline: none

        &-footer
            display: flex
            flex-wrap: wrap
            align-items: center
            gap: 12px
            .btn
                flex-grow: 1

.student-card, .class-card
    @extend .u-box-hoverless
    padding: 12px 16px
    display: flex
    align-items: center
    gap: 24px
    flex-wrap: wrap

    &:hover
        box-shadow: $dropshadow-md

    &--equal-columns
        & > *
            width: 0
            min-width: 100px
            flex-grow: 1
        & > *:not(.student-card__header)
            text-align: center

    &:not(:last-child)
        margin-bottom: 16px

    &__header
        min-width: 100px
        flex: 1
        display: flex
        align-items: center
        gap: 16px
        word-wrap: anywhere
        overflow: hidden

        .student-card__avatar
            flex-shrink: 0

    &__avatar
        flex-shrink: 0
        width: 32px
        height: 32px
        object-fit: cover
        border-radius: 50px

    &__meta
        width: 0
        flex-grow: 1
        flex-shrink: 1
        line-height: 1

    &__name
        @extend .u-text-truncate
        width: 100%
        color: var(--color-primary-dark)
        font-size: .9em
        margin-bottom: .2em
        display: flex
        flex-wrap: wrap
        align-items: center
        gap: 4px

    &__course
        color: #9CA4B5
        font-size: .85em

    &__class
        color: #E95852
        font-size: 1.2rem
        background-image: url("data:image/svg+xml,<svg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9.965 0.199739C10.2304 0.465156 10.2304 0.893072 9.965 1.15849L5.46375 5.65974C5.2525 5.87099 4.91125 5.87099 4.7 5.65974L0.198748 1.15849C-0.0666687 0.893072 -0.0666687 0.465155 0.198748 0.199739C0.464165 -0.0656778 0.892081 -0.0656778 1.1575 0.199739L5.08458 4.12141L9.01167 0.194323C9.27167 -0.0656773 9.705 -0.0656777 9.965 0.199739Z' fill='rgb(235, 86, 80)'/></svg>")
        background-repeat: no-repeat
        background-position: left center
        padding-left: 16px

        &.is-increment
            color: #1EAE63
            background-image: url("data:image/svg+xml,<svg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.199062 5.61844C-0.0663544 5.35302 -0.0663544 4.9251 0.199062 4.65969L4.70031 0.158438C4.91156 -0.0528123 5.25281 -0.0528123 5.46406 0.158438L9.96531 4.65969C10.2307 4.9251 10.2307 5.35302 9.96531 5.61844C9.6999 5.88385 9.27198 5.88385 9.00656 5.61844L5.07948 1.69677L1.1524 5.62385C0.892396 5.88385 0.459062 5.88385 0.199062 5.61844Z' fill='rgb(30, 174, 99)'/></svg>")

    &__date-time
        font-size: 1.2rem
        color: #8894AC

    &__student-email
        @extend .u-text-truncate
        display: inline-block
        font-size: .8em
        color: var(--color-neutral)

    &-wrapper
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
        gap: 12px

        .student-card
            margin-bottom: 0

.class-card
    font-size: 1.4rem

    &__title
        margin-bottom: 0
        font-weight: 300
        color: var(--color-neutral)

    &__average
        margin-bottom: 0
        font-weight: 700
        color: var(--color-primary-dark)

.students-card
    @extend .u-box
    padding: 16px
    display: flex
    flex-direction: column
    gap: 10px

    &__header
        display: flex
        align-items: center
        gap: 12px

    &__img
        flex-shrink: 0
        width: 42px
        height: 42px
        object-fit: cover
        border-radius: 50px
        border: 2px solid #FFFFFF
        filter: drop-shadow(0px 4px 10px rgba(68, 43, 140, 0.1))

    &__data
        flex-grow: 1
        min-width: 0
        background-color: var(--color-bright)
        padding: 8px
        border-radius: 6px
        line-height: 1

    &__name
        @extend .u-text-truncate
        color: var(--color-primary-dark)
        font-size: 1.2rem
        margin-bottom: 0

    &__id
        // @extend .u-text-truncate
        color: var(--color-neutral-darker)
        margin-bottom: 0
        font-size: 1rem

    &__body
        display: flex
        flex-direction: column
        gap: 12px

    &__columns
        display: grid
        gap: 20px
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))

    &__widget
        flex-grow: 1
        flex-basis: 0
        display: flex
        flex-direction: column
        align-items: center
        gap: 4px

    &__widget-container
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 20px

    &__widget-title
        font-size: 1.2rem
        line-height: 1
        color: var(--color-neutral)
        margin-bottom: 0

    &__widget-content
        font-size: 4.6rem
        flex-grow: 1
        display: flex
        align-items: center

    &__widget-value
        margin-bottom: 0
        color: var(--color-neutral-light)
        font-size: 1.2rem
        display: inline-block
        svg
            vertical-align: sub

.avaliation-card
    @extend .u-box
    padding: 16px
    position: relative

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 8px
        margin-bottom: 24px

    &__group
        min-width: 0
        flex-grow: 1
        line-height: 1.2

    &__title
        @extend .u-text-truncate
        display: block
        color: var(--color-primary-dark)
        font-weight: 800
        margin-bottom: 0
        font-size: 1.2rem
        span
            font-weight: 300

    &__status
        display: block
        color: var(--color-neutral)
        font-size: 10px

    &__class
        border-radius: 6px
        background-color: var(--color-bright)
        padding: 8px
        font-weight: 800
        color: var(--color-neutral)
        font-size: 1rem
        flex-shrink: 0
        margin-bottom: 0

    &__icon
        color: var(--color-neutral-darker)

        svg
            width: 14px

    &__btn
        appearance: none
        background: transparent
        border: none
        padding: 0
        flex-shrink: 0

    &__body
        flex-grow: 1
        display: flex
        align-items: flex-end
        gap: 16px
        margin-bottom: 12px

    &__info
        color: var(--color-neutral-darker)
        background: var(--color-bright)
        border-radius: 4px
        font-size: 1.2rem
        font-weight: 800
        text-align: center
        padding: .5em 0
        flex-grow: 1
        flex-basis: 0
        margin-bottom: 0

    &__name
        font-weight: 300

    &__footer
        display: flex
        align-items: center
        gap: 16px
        & > *
            flex-basis: 0
            flex-grow: 1
            display: flex
            place-content: center

    &__comment
        color: var(--color-primary-light)
        font-size: 1.2rem
        text-decoration: underline
        padding: 0 1em

    &__time
        @extend .archive-card__time

        &--danger
            @extend .archive-card__time--danger

.materials-card
    @extend .card
    padding: 16px
    display: flex
    flex-direction: column

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 8px
        margin-bottom: 24px

    &__group
        min-width: 0
        flex-grow: 1
        line-height: 1.2

    &__title
        @extend .u-text-truncate
        display: block
        color: var(--color-primary-dark)
        font-weight: 800
        margin-bottom: 0
        font-size: 1.2rem
        span
            font-weight: 300

    &__status
        display: block
        color: var(--color-neutral)
        font-size: 10px

    &__icon
        color: var(--color-third)

        svg
            width: 14px

    &__btn
        appearance: none
        background: transparent
        border: none
        padding: 0
        flex-shrink: 0

    &__body
        flex-grow: 1
        display: flex
        align-items: center
        gap: 16px
        margin-bottom: 12px

    &__info
        flex-basis: 0
        flex-grow: 1
        color: var(--color-neutral-darker)
        background: var(--color-bright)
        border-radius: 4px
        font-size: 1.2rem
        font-weight: 800
        text-align: center
        padding: .5em 0
        flex-grow: 1
        flex-basis: 0
        margin-bottom: 0

    &__name
        font-weight: 300

    &__updated
        flex-basis: 0
        flex-grow: 1
        color: var(--color-primary-light)
        font-size: 1rem
        line-height: 1.2
        margin-bottom: 0

.documents-card
    @extend .card
    padding: 16px
    display: flex
    flex-direction: column

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 8px
        margin-bottom: 24px

    &__group
        min-width: 0
        flex-grow: 1
        line-height: 1.2

    &__title
        @extend .u-text-truncate
        display: block
        color: var(--color-primary-dark)
        font-weight: 800
        margin-bottom: 0
        font-size: 1.2rem
        span
            font-weight: 300

    &__type
        display: block
        color: var(--color-neutral-darker)
        font-size: 10px
        font-weight: 700

    &__size
        font-weight: 700
        margin-bottom: 0
        font-size: 1rem
        color: var(--color-neutral)

    &__icon
        color: var(--color-third)

        svg
            width: 14px

        &--with-bg
            background-color: var(--color-primary-lightest)
            color: var(--color-primary)
            line-height: 0
            padding: 8px
            border-radius: 4px

    &__body
        flex-grow: 1
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 8px
        margin-bottom: 24px

    &__footer
        display: flex
        align-items: center
        gap: 16px
        & > *
            flex-basis: 0
            flex-grow: 1
            display: flex
            place-content: center

.sidebar-card-wrapper
    display: flex
    flex-direction: column
    gap: 16px

.sidebar-card
    background: #fff
    border: 1px solid var(--color-primary-lightest)
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 10px
    padding: 16px
    display: flex
    flex-wrap: nowrap
    align-items: center
    gap: 10px
    position: relative
    transition: .15s
    cursor: pointer
    @media (min-width: $xxl)
        flex-direction: row

    &:hover
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.1)

    &.active
        background-color: var(--color-primary)
        border-color: var(--color-primary)
        .sidebar-card__img
            border: 0
        .sidebar-card__title, .sidebar-card__description
            color: #fff

    &__img
        flex-shrink: 0
        width: 52px
        height: 52px
        object-fit: cover
        border-radius: 8px
        border: 1px solid var(--color-primary-lightest)

    &__footer
        position: absolute
        width: 20px
        height: 20px
        top: 0
        right: 10px

    &__body
        min-width: 0
        flex-grow: 1
        position: relative
        display: flex
        flex-direction: column
        justify-content: center

    &__title
        color: var(--color-primary)
        font-weight: 800
        font-size: 1.4rem
        line-height: 1.5
        margin-bottom: 0

    &__description
        @extend .u-text-truncate
        color: var(--color-neutral)
        font-size: 1.4rem
        line-height: 1.2
        margin-bottom: 0

    button
        @extend .u-text-truncate
        display: inline-block
        font-size: .82em

    .c-dropdown
        position: absolute
        right: 8px
        top: 8px
        background-color: var(--color-primary-lightest)
        border-radius: 4px
        transition: all ease-in-out .2s
        line-height: 1
        &:hover
            background-color: var(--color-primary-lighter)
            svg
                stroke: white

.activity-card
    background: linear-gradient(253.87deg, #FFFFFF 5.22%, rgba(255, 255, 255, 0) 99.41%), var(--color-bright)
    border: 2px solid var(--color-primary-lightest)
    border-radius: 10px
    padding: 20px
    position: relative
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    transition: all ease-in-out 0.2s

    &.activity-card--hover:hover
        box-shadow: 0px 4px 20px rgba(68, 43, 140, 0.25)

    &__status
        display: inline-block
        border-radius: 50px
        line-height: 1.2
        color: #fff
        font-weight: 300
        font-size: 1.2rem
        background-color: #494949
        border: 1px solid #808080
        padding: 0 4px

        &--absolute
            position: absolute
            top: 4px
            right: 4px

        &--warning
            background-color: #FDB462
            border-color: #FFD661

        &--success
            background-color: #1EAE90
            border-color: #6FCF97

        &--danger
            background-color: #FD6B62
            border-color: #ff7c77

        &--default
            background-color: #32C9D3
            border-color: #74DBE1

    &__header
        @extend .materials-card__header
        margin-bottom: 20px
        align-items: flex-start

        .btn
            flex-shrink: 0

    &__group
        @extend .materials-card__group

        .activity-card__tag
            margin-bottom: 12px

    &__avatar
        flex-shrink: 0
        width: 60px
        height: 60px
        object-fit: cover
        border-radius: 50px

    &__title
        color: var(--color-primary)
        font-size: 1.6rem
        font-weight: 700
        line-height: 1.2
        margin-bottom: 0

        &--md
            font-size: 2rem

    &__subtitle
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        line-height: 1.2
        margin-bottom: 0

    &__info
        color: var(--color-primary-light)
        font-size: 1.2rem

    &__available
        color: #000
        font-weight: 300
        font-size: 1rem
        line-height: 150%

    &__scale
        color: #000
        font-weight: 300
        font-size: 1.4rem
        line-height: 150%

        svg
            color: #FFD661
            fill: #FFD661
            width: 16px

    &__text
        color: var(--color-neutral)
        font-size: 1.4rem
        margin-bottom: 4px

    &__body
        margin-bottom: 12px

    &__description
        font-size: 1.4rem
        padding-bottom: 36px
        margin-bottom: 36px
        border-bottom: 1px solid var(--color-neutral-lightest)

        &:last-of-type:not(:first-of-type)
            border-bottom: 0
            padding-bottom: 0
            margin-bottom: 0

    &__footer
        display: flex
        align-items: center
        justify-content: space-between
        gap: 4px

    &__tag
        color: var(--color-primary-light)
        background-color: var(--color-primary-lightest)
        font-size: 1.2rem
        font-weight: 700
        line-height: 1.5
        padding: 4px 8px
        border-radius: 5px
        display: inline-flex
        align-items: center
        gap: 4px

        svg
            width: 16px
