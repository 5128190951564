.container {
  position: fixed;
  z-index: 106;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: #fafafa;
  height: 68px;
  padding: 0 28px !important;

  @media (min-width: 784px) {
    background-color: transparent;
    width: auto;
    height: 40px;
    right: 104px;
  }

  .logoCanalEducacao,
  .logoSeduc,
  .logoAceleraSeduc {
    width: auto;
  }

  .logoCanalEducacao,
  .logoSeduc {
    height: 30px;
  }

  .logoAceleraSeduc {
    height: 24px;
  }
}
