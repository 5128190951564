.noteAndCompetence {
  display: flex;
  align-items: center;
  gap: 8px;
}

.competence {
  flex-grow: 1;
  display: inline-block;
  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  cursor: default;
  background-color: var(--color-bright);
  border-radius: 4px;
  line-height: 2.2rem;
  padding: 0 8px;
  font-weight: 300;
  font-size: 1.2rem;
  color: var(--color-neutral);
}

.note {
  flex-shrink: 0;
  min-width: 34px;
  text-align: right;
}

.responsiveTable {
  width: 98.5%;
  min-width: 904px;

  @media (min-width: 1200px) {
    width: 98.5%;
  }

  @media (min-width: 1900px) {
    width: 98.89%;
  }
}
